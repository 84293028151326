function SavedPerDeploymentSvg() {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.95833 1V23M11.9236 5H4.47569C3.55388 5 2.66983 5.36875 2.01801 6.02513C1.36619 6.6815 1 7.57174 1 8.5C1 9.42826 1.36619 10.3185 2.01801 10.9749C2.66983 11.6313 3.55388 12 4.47569 12H9.44097C10.3628 12 11.2468 12.3687 11.8987 13.0251C12.5505 13.6815 12.9167 14.5717 12.9167 15.5C12.9167 16.4283 12.5505 17.3185 11.8987 17.9749C11.2468 18.6313 10.3628 19 9.44097 19H1"
        stroke="#6DF3D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8801 3.75C14.8279 3.75 14.7809 3.78061 14.76 3.82908C14.7391 3.87755 14.7521 3.93112 14.7887 3.96684L20.1599 9.21173C20.186 9.23724 20.22 9.25 20.2513 9.25C20.2827 9.25 20.3192 9.23724 20.3427 9.21173L25.7113 3.96939C25.7479 3.93622 25.7609 3.8801 25.74 3.82908C25.7191 3.78061 25.6721 3.75 25.6199 3.75H14.8801Z"
        fill="#6DF3D9"
      />
    </svg>
  );
}

export default SavedPerDeploymentSvg;
