function ServingEnterprisesSvg() {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.75 19V7.75M14.5 19V1M1 19V14.5"
        stroke="#67E4D1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ServingEnterprisesSvg;
