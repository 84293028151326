import { NextHead } from "../src/landingPage/layouts/LayoutComponents/NextHead";
import { HomePage } from "../src/landingPage/views/HomePage/HomePage";
import { meta } from "../src/landingPage/datasets/meta";

const Home = () => {
  return (
    <>
      <NextHead
        displayCompanyName={false}
        title={meta.homepage.title}
        description={meta.homepage.description}
        currentPath="/"
      />
      <HomePage />
    </>
  );
};

export async function getServerSideProps() {
  return {
    props: {
      wrapper: "MarketingWrapper",
    },
  };
}

export default Home;
