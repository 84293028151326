import Farmers from "../landingPage/views/assets/logos/Farmers.svg";
import StandardChartered from "../landingPage/views/assets/logos/StandardChartered.svg";
import Anz from "../landingPage/views/assets/logos/Anz.svg";
import Momnt from "../landingPage/views/assets/logos/Momnt.png";
import CincinnatiFinance from "../landingPage/views/assets/logos/CincinnatiFinance.png";
// import IAG from "../landingPage/views/assets/logos/IAG.png";
import NAB from "../landingPage/views/assets/logos/NAB.svg";

import FarmersColor from "../landingPage/views/assets/logos/Farmers-color.svg";
import StandardCharteredColor from "../landingPage/views/assets/logos/StandardChartered-color.svg";
import AnzColor from "../landingPage/views/assets/logos/Anz-color.svg";
import MomntColor from "../landingPage/views/assets/logos/Momnt-color.png";

export const logos = {
  default: [
    { src: NAB.src, alt: "NAB", width: "150", height: "60" },
    { src: Anz.src, alt: "ANZ", width: "100", height: "35", isAnz: true },
    { src: Momnt.src, alt: "Momnt", width: "110", height: "22" },
    { src: StandardChartered.src, alt: "Standard Chartered", width: "111", height: "45" },
    { src: Farmers.src, alt: "Farmers Insurance", width: "82", height: "44" },
    // { src: IAG.src, alt: "IAG", width: "80", height: "80" },
    {
      src: CincinnatiFinance,
      alt: "Cincinnati",
      width: "120",
      height: "120",
      isBrightLogo: true,
    },
  ],
  color: [
    { src: AnzColor.src, alt: "ANZ", width: "96", height: "31" },
    { src: FarmersColor.src, alt: "Farmers Insurance", width: "82", height: "44" },
    { src: StandardCharteredColor.src, alt: "Standard Chartered", width: "111", height: "45" },
    { src: MomntColor.src, alt: "Momnt", width: "110", height: "22" },
  ],
};
