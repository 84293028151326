function LessCostSvg() {
  return (
    <svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 14.0907L14.6364 3.72709L9.18182 9.18164L1 0.999816"
        stroke="#6DF3D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4545 14.0907H25V7.54527"
        stroke="#6DF3D9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LessCostSvg;
