import clsx from "clsx";
import Image from "next/image";
import styles from "./Customers.module.less";
import { logos } from "../../../constants/CustomerLogos";

export const Customers = ({
  className,
  header = "Join the customers already unlocking the power of external data with Demyst:",
  variant = "default",
  align = "center",
  withMargin = false,
}) => {
  return (
    <div
      className={clsx(
        styles.Customers,
        withMargin && styles.CustomersMargin,
        align === "center" && styles.CustomersAlignCenter,
        className,
      )}
    >
      {header && (
        <p className={styles.CustomersHeader} style={{ marginBottom: "40px" }}>
          {header}
        </p>
      )}

      <div className={styles.CustomersLogosSlider}>
        <div className={styles.CustomersLogosTrack}>
          {Array(2)
            .fill()
            .map(() =>
              logos[variant]?.map(i => (
                <div
                  key={i.alt}
                  className={clsx(styles.CustomersLogosSlide, i.isBrightLogo && styles.BrightLogo)}
                >
                  <Image
                    priority={false}
                    src={i.src}
                    alt={i.alt}
                    width={i.width}
                    height={i.height}
                  />
                </div>
              )),
            )}
        </div>
      </div>
    </div>
  );
};
