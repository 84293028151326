import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Attention from "../../../assets/svg/InfoBox/Attention.svg";
import Error from "../../../assets/svg/InfoBox/Error.svg";
import Success from "../../../assets/svg/InfoBox/Success.svg";
import { FiX } from "react-icons/fi";
import { linksConstants } from "../../../../constants/linksConstants";
import Link from "next/link";

const getIcon = type => {
  switch (type) {
    case "Attention":
      return Attention.src;
    case "Success":
      return Success.src;
    default:
      return Error.src;
  }
};

const getMessage = type => {
  switch (type) {
    case "Attention":
      return "The page you tried to access does not exist. If you think this is a mistake, please contact Demyst support.";
    case "Success":
      return "Your form has been sent successfully. We'll be in touch.";
    default:
      return "There was an unexpected internal error with our server. Please contact Demyst support.";
  }
};

const getActionText = type => {
  switch (type) {
    case "Success":
      return "Return Home";
    default:
      return "Contact Support";
  }
};

const getActionLink = type => {
  switch (type) {
    case "Success":
      return linksConstants.HOME;
    default:
      return linksConstants.CONTACT;
  }
};

export const InfoBox = ({
  actionLink,
  actionText,
  actionVisible = true,
  closeModal,
  fromContact = false,
  text,
  title,
  type = "Success",
}) => {
  const [componentMounted, setComponentMounted] = useState(false);

  useEffect(() => {
    setTimeout(() => setComponentMounted(true), 200);
  }, []);

  const closeWithFade = () => {
    setComponentMounted(false);
    setTimeout(() => {
      closeModal();
    }, 500);
  };

  return (
    <section className={`info_box-main ${componentMounted ? "" : "initiated"}`}>
      <div className={`info_box-icon_box ${type.toLowerCase()}`}>
        <img className="info_box-icon" src={getIcon(type)} alt={type} />
      </div>
      <div className="info_box-text">
        <h3 className="info_box-header">{title || `${type}!`}</h3>
        <p className="info_box-message">{text || getMessage(type)}</p>
        {actionVisible && !fromContact && (
          <Link
            onClick={closeModal}
            className="info_box-action"
            href={actionLink || getActionLink(type)}
          >
            {actionText || getActionText(type)}
          </Link>
        )}
        {actionVisible && fromContact && (
          <a
            onClick={closeModal}
            className="info_box-action"
            href={actionLink || getActionLink(type)}
          >
            {actionText || getActionText(type)}
          </a>
        )}
      </div>
      <button className="info_box-close" onClick={() => closeWithFade()} title="Close">
        <FiX />
      </button>
    </section>
  );
};

InfoBox.propTypes = {
  actionLink: PropTypes.string,
  actionText: PropTypes.string,
  actionVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  fromContact: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string, // Attention, Error, Success
};
